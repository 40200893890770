@import "../src/Style/variable.scss";

body,
html {
  padding: 0;
  margin: 0;
  font-family: "Inter" !important;
}

.cancel-btn {
  border-radius: 10px;
  border: 1px solid $primary-color;
  cursor: pointer;
  color: $primary-color;
}

.submit-btn {
  border-radius: 10px;
  border: 1px solid $primary-color;
  background: $primary-color;
  color: $color-white;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

input,
select,
textarea {
  outline: none !important;
  box-shadow: none !important;
}

.link-tag {
  text-decoration: none;
}

.border-app-nav {
  margin: 0 1.5rem;
}

.border-app-body {
  margin-top: 6rem !important;
  margin: 0 2rem;
}

.toast-top-right {
  margin-top: 60px;
}

.search-icon {
  color: $search-icon;
}

@media only screen and (max-width: 800px) {
  .border-app-nav {
    margin: 0 0;
  }

  .border-app-body {
    margin: 0 0.7rem;
  }

  .main-cards {
    // display: block !important;

    .card {
      width: 100% !important;
      margin-bottom: 1rem;

    }

  }

  .table-scroll {
    width: 100%;
    overflow-y: auto;
  }

}

.delete-background {
  background-color: $delete-backgroung;
  margin-left: 10px;
  color: $color-error;
  padding: 5px;
  border-radius: 20px;
}

@media only screen and (max-width: 900px) {

  .section-one-para,
  .section-two-para {
    .row {
      .border-end-column {
        border-right: 0 !important;
        height: 70px !important;
        margin-left: 0rem !important;
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .main-cards {
    .card {
      margin-bottom: 1rem;
    }
  }
}

.dashboard-active {
  .card {
    border: 0.5px solid $primary-color !important;
  }
}

label {
  font-size: 0.8rem !important;
  font-weight: bold;
}

.form-control:focus {
  border-color: $color-black !important;
}

.lds-default,
.lds-default div {
  box-sizing: border-box;
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-default div {
  position: absolute;
  width: 6.4px;
  height: 6.4px;
  background: currentColor;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}

.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 36.8px;
  left: 66.24px;
}

.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22.08px;
  left: 62.29579px;
}

.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11.30421px;
  left: 51.52px;
}

.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7.36px;
  left: 36.8px;
}

.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11.30421px;
  left: 22.08px;
}

.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22.08px;
  left: 11.30421px;
}

.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 36.8px;
  left: 7.36px;
}

.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 51.52px;
  left: 11.30421px;
}

.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62.29579px;
  left: 22.08px;
}

.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66.24px;
  left: 36.8px;
}

.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62.29579px;
  left: 51.52px;
}

.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 51.52px;
  left: 62.29579px;
}

@keyframes lds-default {

  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

.view-parameter {
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.shadow.rounded {
  .row {
    align-items: center;
  }
}

.view-parameters {
  color: $primary-color;
}


audio::-webkit-media-controls-panel {
  background: transparent !important;
}

audio::-webkit-media-controls-timeline-container {
  visibility: hidden;
}

.section-one-para,
.section-two-para {
  .row {
    .border-end-column {
      border-right: 1px solid $color-vericalline;
      height: 90px;
      margin-left: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.rhap_additional-controls,
.rhap_volume-container,
.rhap_current-time {
  display: none !important;
}

.rhap_progress-container {
  margin-left: 30px !important;
}

.rhap_total-time {
  margin-left: 75px !important;
}

.rhap_container {
  width: 300px !important;
  box-shadow: none !important;
}

.rhap_main-controls-button {
  color: $primary-color !important;
}

.rhap_progress-filled,
.rhap_progress-indicator {
  background-color: $primary-color !important;
}

.audio-relative {
  position: relative;
}

.audio-absolute {
  position: absolute;
  top: -15px;
  left: 30%;
}

.rhap_progress-section {
  flex-flow: row-reverse;
}

.rhap_main-controls {
  position: absolute;
  top: 0px;
}

.add-food-images-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  border-radius: 12px;
  border: 1px dashed $primary-color;
  position: relative;
  background-color: $color-input-upload;
  padding: 1rem;
}

.upload-button-image {
  padding: 10px !important;
  gap: 10px !important;
  border-radius: 8px !important;
  border: 1px solid $primary-color !important;
  color: $color-black !important;
  font: inter,
}

button {
  cursor: pointer;
}

.leftscroll {
  ::-webkit-scrollbar {

    height: 3px !important;
  }
}

.header-SubmitResearchermodel {
  font-size: 50px;
}




@media only screen and (max-width: 500px) {
  .rhap_container {
    width: 200px !important;
    box-shadow: none !important;
  }
  .rhap_main-controls {
    position: absolute;
    top: -13px;
}
.rhap_stacked-reverse{
  position: absolute;
  left: -14px;
  width: 200px !important;
}
.delete-icon{
  margin-top: 20px;
}
}

.loader {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: block;
  margin:10px auto;
  position: relative;
  color: white;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
  padding: 0;
}

@keyframes animloader {
  0% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
  25% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 2px;
  }
  50% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 2px,  -38px 0 0 -2px;
  }
  75% {
    box-shadow: 14px 0 0 2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
  100% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
}
.viewpara-css{
  box-shadow: 0px 0px 8px 0px lightgrey;
  margin: 0 !important;
  .col-sm-3{
    border-right: 1px solid lightgray ;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $primary-color;
}

input:focus + .slider {
  box-shadow: 0 0 1px $primary-color;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}