@import './variable.scss';

.card-main-div {
    .card-icon {
        background-color: $color-bg-img;
    }

    .card-data {
        font-size: clamp(0.7rem, 0.011rem + 0.5vw, 1rem);
    }
}

.adduser,
.recipesdetailsadddetails {
    .error-message {
        position: absolute;
        left: 0;
        color: $color-error;
        font-size: 0.7rem;
    }

    .input-container-adduser {
        position: relative;
       
    }
}
.fw-bold-2{
    font-weight: 550 !important;
    }

.adduser {
    .red-star {
        color: $color-error;
    }
   
}
.fw-bold-2{
    font-weight: 500 !important;
}
.formdata-selectcountary {
    font-size: 10px;
}

.formdata-selectcountary {
    &__control {
        height: 35px;
        max-width: 400px;
    }

    &__menu {
        max-height: 150px;
        overflow: hidden;
    }

    &__menu-list {
        max-height: 150px;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    &__menu-list::-webkit-scrollbar {
        display: none;
    }
}

.padding-margin {
    margin: 0rem 3rem;
    padding-left: 2rem;
}

.RecipesView,.view {
    .RecipesView-main,.view-main{
        max-height: 78vh;
        overflow-x: auto;
        box-shadow: 0px 0px 9px 3px $color-disable;
        border: 0;
    }

    .RecipesView-main .form-control {
        background-color: $color-disable;
        border: none;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $color-border;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: $primary-color;
        border-radius: 10px;
    }
}

.admin-excel {
    .admin-excel-main {
        height: 180px;
        top: 134px;
        left: 1051px;
        border: 1.08px dashed $color-black;
        background:$color-disable;
        border-radius: 12px;
        // margin: 7%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .choose-file {
            display: none;
        }

        .supported-formats {
            color: $color-format;
        }
    }

    .file-name {
        margin: 0;
        flex-grow: 1;
    }

   
    .download-text {
        text-decoration: none; /* Remove default underline */
        color: $color-download; /* Inherit the text color */
        cursor: pointer;
      }
      
      .download-text:hover {
        text-decoration: underline;
      }
}


.displaytable{
    display: flex;
    justify-content: space-between;
}

.admin-displaytable{
    width: 77%;
}
.admin-displayexcel{
    width: 22%;
    margin-top: 1.1rem;
}
.form-select__indicator-separator{
    display: none !important;
}


@media only screen and (max-width: 1200px) {
    .RecipesView .right {
        margin-left: 0px !important;
    }

    .button-media {
        justify-content: center;
        flex-direction: column;
    }

  

    .padding-margin {
        margin: 1rem 1rem !important;
        padding: 0 !important;
    }

    .col-md-3,
    .col-sm-4,
    .col-sm-2 {
        padding-bottom: 1rem;
        width: 100% !important;
    }
    .displaytable{
       flex-flow: column;
    }
    .admin-displaytable{
        width: 100%;
    }
    .admin-displayexcel{
        width: auto;
        margin-top: 1rem;
    }

}



// AdminReassignModel.scss

.admin-reassign-modal {
   
  
    .current-name {
      margin-top: 1.25rem;
  
      .label {
        font-weight: 500;
        color: $color-black;
      }
  
      .input-wrapper {
        margin-top: 0.5rem;
  
        input {
          width: 100%;
        }
      }
    }
  
    .reassign-name {
      margin-top: 1rem;
  
      .label-wrapper {
        display: flex;
        align-items: center;
        .label {
            font-weight: 500;
            color:$color-black;
          }
        .required-star {
          color: $color-error;
          font-size: 1rem;
        }
      }
  
      .select-wrapper {
        margin-top: 0.5rem;
  
        select {
          width: 100%;
        }
  
        .error-message {
          color: $color-error;
          font-size: 0.8125rem; 
        }
      }
    }
  
    .buttons {
     
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .button {
        &.cancel-btn,
        &.submit-btn {
          padding: 0.5rem 1rem;
          cursor: pointer;
        }
      }
    }
  }
  

  .search-zipcode{
    position: relative;
  }
  .search-zipcode-icon{
    position: absolute;
    right: 5px;
    top: 8px;
  }
.dashboard-cards{
    width: 19rem;
    cursor: pointer;
}

