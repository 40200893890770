@import "./variable.scss";

.RecipesDetail ::-webkit-scrollbar {
  width: 6px;
}
.RecipesDetail ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $color-border;
  border-radius: 10px;
  height: 70vh;
}
.RecipesDetail ::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 10px;
}

audio::-webkit-media-controls-panel {
  filter: hue-rotate(40deg);
}

audio::-webkit-media-controls-mute-button {
  background-color: $color-bg-rework;
  border-radius: 50%;
}

.RecipesDetail {
  .RecipesDetail-main {
    max-height: 76vh;
    overflow-x: auto;
    box-shadow: 0px 0px 9px 3px $color-disable;
    border: 0;
  }

  .add-button {
    width: 44px;
    height: 42px;
    padding: 5px;
    border-radius: 40px;
    background:$primary-color;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .add-button::before {
    content: "+";
    color: $color-white;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .delete-button {
    width: 44px;
    height: 42px;
    padding: 5px;
    border-radius: 40px;
    background: $primary-color;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-white;
  }
  .scrollable-wrapper {
    min-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 200px;
  }
  .return-to-list {
    width: 150px;
    height: 44px;
    border: 1px solid;
    border-radius: 10px;
    background: $color-white;
    border-color:$primary-color;
    color:$primary-color;
  }
  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    background: $color-white;
    border-radius: 50%;
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    grid-gap: 30px;
  }
  .delete-icon {
    cursor: pointer;
    color: $color-error;
    margin-left: 10px;
    margin-top: 20px;
  }

  .stop-icon {
    cursor: pointer;
    color: $color-white;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary-color;
    height: 35px;
    width: 35px;
    border-radius: 50%;
  }
  .dropdown-name {
    max-height: 150px;
    overflow-x: auto;
    cursor: pointer;
    z-index: 5;
  }

}
.red-star {
  color: $color-error;
}
.input-box-ingredient-data{
  margin-top: 0.5rem !important;
}
@media only screen and (max-width: 800px) {
  .right {
    margin-left: 0px !important;
  }
  .input-box-ingredient{
    margin-top: 1rem;
  }
  .input-box-ingredient-data{
    margin-top: 2rem !important;
  }
}
@media only screen and (min-width: 1050px) and (max-width: 1300px) {
  .RecipesDetail .col-md-3 {
    padding-bottom: 1rem;
    width: 50% !important;
  }
  .input-box-ingredient-data{
    margin-top: 2rem !important;
  }
 
}

@media only screen and (min-width: 800px) and (max-width: 1050px) {
 
  .input-box-ingredient-data2{
    margin-top: 2rem !important;
  }
 
 
}


.custom-select-researcher__control {

  &--is-focused {
    border-color: black !important;
    box-shadow: 0 0 0 1px rgb(255, 255, 255) !important;
  }



}

.custom-select-researcher__menu > div {
  max-height: 100px !important; 
  min-height: 100px !important; 
  overflow-y: auto;
}

.cursor-not-allowed {
  cursor: not-allowed;
}