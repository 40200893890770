@import '../Style/variable.scss';

.DataTable {
  width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: left;
  padding: 7px;
  
}

th {
  background: $color-disable;
}

tr {
  border: 0.5px solid $color-disable !important;
}

tbody {
  border-color: $color-disable;
}

.searchinput {
  height: 44px;
  border-radius: 8px;
  border: 1px solid $color-disable;
  padding-left: 45px;
  width: 400px;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-container {
  width: 100%;
  overflow-x: auto;

  border-radius: 10px;
}

.DataTableresearch {
  position: relative;
}

.DataTableresearch-search {
  position: absolute;
  top: 10px;
  left: 10px;
}

.pagination-container {
  display: flex;

  align-items: center;
  margin: 20px 0;
}

.pagination {
  list-style: none;
  display: flex;
  gap: 5px;
}

.page-item {
  display: inline;
}

// .page-link {
//   padding: 8px 16px;
//   margin: 0 4px;
//   border: 1px solid #ddd;
//   cursor: pointer;
//   background-color: #fff;
// }

// .page-link:disabled {
//   cursor: not-allowed;
//   opacity: 0.5;
// }

// .page-item .active .page-link {
//   background-color: #007bff;
//   color: black;
//   border-color: #007bff;
// }

.color-edit {
  color: $color-black;
}
.status{
  width: 300px;
}
.roundpill-status{
  width: 200px;
}
// 





.edit-icon{
  color: $search-icon;
  // padding: 2px;
}

@media screen and (max-width: 850px) {
  .input-container {
    flex-flow: column;
  }

  .searchbox-top {
    margin-top: 1rem;
  }
  .custom-select-wrapper{
    flex-flow: column;
   margin-top: 1rem;
  }
  .pagination{
    margin-top: 1rem;
  }
  .custom-select-wrapper-1{
    flex-flow: column;
    align-items: center;
  }
  .searchinput{
    width: 100%;
  }
  .flex-filter{
    flex-flow: column;
    align-items: center;
  }
  .datatable-display-filter{
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 1197px) {
  .admin-view {
   margin-bottom: 20px !important;
  }
}


.select-input-arrow{
  position: relative;
  width: 120px !important;
}
.select-input-arrow-down{
  position: absolute;
  top: 9px;
  right: 25px;

  &.inactive {
   
    color: $color-inactive !important;
  }

  &.active {
   
    color: $color-active !important;
  }

  // :focus {
  //   border: inherit !important;
  // }
}
.pagination button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin: 0 5px;
  width: 30px;
  height: 30px;
}

.pagination button.active {
  border: 1px solid $color-black;
  border-radius: 5px;
  color: $color-white;
  background-color: $color-black;
}

.linkcolor {
  text-decoration: none;
  color: $primary-color;
}

.tablemetrixdata {
  color: $secondary-color;
}

.selectbox-width-datatable {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  border-radius: 30px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  height: 30px;
  width: 110px !important;

  


  &.inactive {
    background-color: $color-bg-inactive;
    color: $color-inactive;
    background-image: url('../Assets/arrow_red.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 15px 15px; 
  }

  &.active {
    background-color: $color-bg-active;
    color: $color-active;
    background-image: url('../Assets/arrow_green.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center;
    background-size: 15px 15px; 
  }

  :focus {
    border: inherit !important;
    background-color:inherit !important;
    color: inherit !important;
  }

  option {
    &.inactive {
      color: $color-inactive;
      background-color: $color-bg-inactive;
      cursor: pointer;

      &[hidden] {
        display: none;
      }
    }

    &.active {
      color: $color-active;
      background-color: $color-bg-active;
      cursor: pointer;

      &[hidden] {
        display: none;
      }
    }
  }
}

$statuses: (
  "To-Do": ("background": $color-bg-todo, "text": $color-todo),
  "In-Progress": ("background": $color-bg-inprogress, "text": $color-inprogress),
  // "COMPLETED": ("background": $color-bg-approved, "text": $color-approved),
  "Ready-To-Review": ("background": $color-bg-review, "text": $color-review),
  "Sent-To-Rework": ("background": $color-bg-rework, "text": $color-rework),
  "Approved": ("background": $color-bg-approved, "text": $color-approved),
  "DEFAULT": ("background": $color-bg-default, "text": $color-black),
  "Reworked":("background":$color-bg-REWORK-READY-TO-REVIEW,"text":$color-REWORK-READY-TO-REVIEW)
);

@each $status, $colors in $statuses {
  .status-#{$status} {
    font-size: 14px;
    background-color: map-get($colors, "background");
    color: map-get($colors, "text");
  }
}

.DataTable td:first-letter {
  text-transform: uppercase;
}

.input-checked{
  width:17px;
height:17px;
accent-color: $primary-color;
}

.custom-select{
  height: 44px;
  width:  173px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('../Assets/filter_list_1.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
 
} 
.table-body{
  color: $table-content-color;
}


.checkbox-data-status{
  accent-color: $primary-color;
  height: 15px;
  width: 15px;
}