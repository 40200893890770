@import './variable.scss';

.homepage {
  .omeimg {
    background-image: url("../Assets/Homepage/HomeFood1new.jpg");
  }

  .carousel-caption {
    left: 5% !important;
    width: 45%;
    text-align: left;
    top: 20%;
  }

  .carousel-dark {
    .carousel-caption {
      color: $color-black !important;
    }

    .carousel-indicators {
      [data-bs-target] {
        background-color: $color-white !important;
      }
    }
  }

  .go-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    border-radius: 0 4px 0 32px;
  }

  .go-arrow {
    margin-top: -4px;
    margin-right: -4px;
    color: $color-white;
    font-family: courier, sans;
  }

  .card1 {
    display: block;
    position: relative;
    max-width: 262px;
    background-color: $color-white;
    border-radius: 4px;
    padding: 24px 20px;
    margin: 12px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: -16px;
      right: -16px;
      height: 32px;
      width: 43px;
      border-radius: 32px;
      transform: scale(1);
      transform-origin: 50% 50%;
      transition: transform 0.25s ease-out;
    }

    &:hover:before {
      transform: scale(21);
    }
  }

  .card-backrgroud1 {
    &:before {
      background: linear-gradient(45deg, #f5c753, #e68a3e);
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .card-backrgroud2 {
    &:before {
      background: linear-gradient(45deg, #e27547, rgba(232, 149, 77, 0.808));
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .card-backrgroud3 {
    &:before {
      background: linear-gradient(45deg, #0074ff, #ffdc67);
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .card-backrgroud4 {
    &:before {
      background: linear-gradient(45deg, red, #ffdc67);
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .card-backrgroud5 {
    &:before {
      background: linear-gradient(45deg, green, #ffdc67);
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .card-backrgroud6 {
    &:before {
      background: linear-gradient(45deg, rgb(4, 177, 235), #ffdc67);
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .card-backrgroud7 {
    &:before {
      background: linear-gradient(45deg, brown, #ffdc67);
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .card-backrgroud8 {
    &:before {
      background: linear-gradient(45deg, violet, #ffdc67);
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .cardsdata {
    background-color: $homepagefont;
  }


  .marquee-content {
    .card {
      display: inline-block;
      width: 18rem;
      margin: 1.5rem;
      box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2);
      transition: 0.1s;
      padding: 0.25rem 1rem;
    }
  }

  .marquediv {
    background: linear-gradient(45deg, #d6aed6, #98d9e1);
  }

  .card {
    &:hover {
      box-shadow: 0 0.5rem 3rem 0 rgba(0, 0, 0, 0.2);
    }
  }

  .card1:hover {
    p {
      transition: all 0.3s ease-out;
      color: rgba(255, 255, 255, 0.8);
    }

    h3 {
      transition: all 0.3s ease-out;
      color: $color-white;
    }
  }
}

.headings {
  font-size: 20px;
}

.small {
  font-size: 14px;
}

.fixed-nav {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $color-white;
  z-index: 1000;
  border-bottom: 1px solid $color-white;
  padding-left: 1rem;
  padding-right: 1rem;

  @media (max-width: 767px) {
    .homepage {
      .mt-3 {
        margin-top: 0.5rem !important;
      }
      .mb-2 {
        margin-bottom: 0.25rem !important;
      }
      .w-75 {
        width: 50% !important;
      }
      .mx-2 {
        margin-left: 0.25rem !important;
        margin-right: 0.25rem !important;
      }
      .btn {
        font-size: 0.8rem !important;
        padding: 0.3rem 0.6rem !important;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .homepage {
      .mt-3 {
        margin-top: 0.75rem !important;
      }
      .mb-2 {
        margin-bottom: 0.5rem !important;
      }
      .w-75 {
        width: 60% !important;
      }
      .mx-2 {
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
      }
      .btn {
        font-size: 0.9rem !important;
        padding: 0.4rem 0.8rem !important;
      }
    }
  }

  @media (min-width: 992px) {
    .homepage {
      .mt-3 {
        margin-top: 1rem !important;
      }
      .mb-2 {
        margin-bottom: 0.5rem !important;
      }
      .w-75 {
        width: 75% !important;
      }
      .mx-2 {
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
      }
      .btn {
        font-size: 1rem !important;
        padding: 0.5rem 1rem !important;
      }
    }
  }
}


.login-page {
  background: radial-gradient(30% 55% at left, #28272C 0 100%, #0000 100%);
  height: 100vh;

  .remeber-me {
    color: $remember-me;
  }

  .form-check-input:checked {
    background-color:$logincheckbox;
    border-color:$logincheckbox;
  }

  .vizzhy-logo {
    height: 31px;
  }

  .login-img {
    width: 93%;
    height: 90vh;
  }

  .login-icon {
    position: relative;
  }

  .checkmail {
    color: $color-checkmail;
  }

  .mail-eye-icons {
    position: absolute;
    right: 10px;
    top: 25%;
    right: 10px;
    cursor: pointer;
    z-index: 1;
    color:$maileye;
  }
  .link-container {
    display: inline-block;
  }
  
  .link-container .link-tag {
    text-decoration: none; /* Remove default underline */
    color: inherit; /* Inherit the text color */
  }
  
  .link-container:hover .link-tag {
    text-decoration: underline;
  }

  .login-form {
    width: 65%;
    margin: auto;

    .forgotpassword-text {
      color: $primary-color;
    }

    .form-control-lg {
      font-size: 1.0rem;
    }

    .error-message {
      font-size: 13px;
    }

    .login-button {
      background-color: $primary-color;
      border: none;
      height: 50px;
      border-radius: 10px;

      &:hover {
        background-color: $color-hover;
      }
    }
  }
  .Forgotlink {
    text-decoration: none; /* Remove default underline */
    color: inherit; /* Inherit the text color */
  }
  
  .Forgotlink:hover {
    text-decoration: underline;
  }
}


@media screen and (max-width: 850px) {
  .login-page {
    height: 75vh;

    .login-main {
      background-color: $color-black;
      width: 100%;
      height: 90vh;
      display: flex;
      flex-flow: column;
      margin: auto;
    }

    .login-form {
      width: 100%;
    }

    .col-sm-7,
    .col-sm-5 {
      width: 100%;
      margin: auto;
    }

    .vizzhy-logo {
      margin-left: 1rem;
    }
  }
}

@media screen and (min-width: 850px) and (max-width: 1080px) {
  .login-page {
    .login-form {
      width: 100%;
    }
  }
}

.forgotpassword-page {
  background: radial-gradient(30% 55% at left, #28272C 0 100%, #0000 100%);
  height: 100vh;

  .Forgotlink {
    text-decoration: none; /* Remove default underline */
    color: inherit; /* Inherit the text color */
  }
  
  .Forgotlink:hover {
    text-decoration: underline;
  }
  .vizzhy-logo {
    height: 31px;
  }

  .forgotpassword-img {
    width: 93%;
    height: 90vh;
  }

  .login-icon {
    position: relative;
  }

  .mail-eye-icons {
    position: absolute;
    right: 10px;
    top: 25%;
    cursor: pointer;
    z-index: 1;
    color: $maileye;
  }

  .forgotpassword-form {
    width: 65%;
    margin: auto;

    .forgotpassword-text {
      color: $primary-color;
    }

    .form-control-lg {
      font-size: 1.0rem;
    }

    .error-message {
      font-size: 13px;
    }

    .reset-button {
      background-color: $primary-color;
      border: none;
      color: $color-white;
      border-radius: 10px;

      &:hover {
        background-color: $color-hover;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .forgotpassword-page {
    height: 75vh;

    .forget-pass {
      margin: auto;

      .forgotpassword-img {
        margin-left: 0 !important;
      }
    }

    .login-main {
      background-color: $color-black;
      width: 100%;
      height: 90vh;
      display: flex;
      flex-flow: column;
      margin: auto;
    }

    .forgotpassword-form {
      width: 100%;
    }

    .col-sm-7,
    .col-sm-5 {
      width: 100%;
      margin: auto;
    }

    .vizzhy-logo {
      margin-left: 1rem;
    }
  }
}

@media screen and (min-width: 850px) and (max-width: 1080px) {
  .forgotpassword-page {
    .forgotpassword-form {
      width: 100%;
    }
  }
}

.setnewpassword-page {
  background: radial-gradient(30% 55% at left, #28272C 0 100%, #0000 100%);
  height: 100vh;


  .vizzhy-logo {
    height: 31px;
  }

  .setnewpassword-img {
    width: 93%;
    height: 90vh;
  }

  .setnewpassword-form {
    width: 65%;
    margin: auto;

    .fs-3 {
      font-size: 2rem;
      font-weight: bold;
    }

    .login-icon {
      position: relative;

      .error-message {
        color:$color-error
      }

      .success-message {
        color:$color-approved;
      }

      .mail-eye-icons {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        color: $maileye;
    }
    }

    .reset-button {
      color: $color-white;
      background-color: $primary-color;
      border: none;
      padding: 0.75rem 1.25rem;
      font-size: 1rem;
      border-radius: 10px;
    }
  }
}

.expired-link {
  height: 100vh;
}


@media (max-width: 850px) {
  .setnewpassword-page {
    height: 75vh;

    .login-main {
      background-color: $color-black;
      width: 100%;
      height: 90vh;
      display: flex;
      flex-flow: column;
      margin: auto;

      .vizzhy-logo {
        margin-left: 1rem;
      }

      .setnewpassword-img {
        margin-left: 0;
        width: 80%;
      }

      .setnewpassword-form {
        width: 100%;

        .fs-3 {
          font-size: 2rem;
        }

        .reset-button {
          font-size: 0.875rem;
          padding: 0.5rem 1rem;
        }
      }
    }
  }
}

.profile {
  .profile-icon {
    &:hover {
      color: #555;
    }
  }

  .background-blur {
    filter: blur(5px);
    pointer-events: none;
  }

  .profile-wrapper {
    position: relative;
    z-index: 999;
  }
  .collapse {
    display: none;
    transition: max-height 0.3s ease-out;

    &.show {
      display: block;
      position: absolute;
      right: 50px;
      z-index: 1;
      top: 1rem
    }
  }

  .card-body {
    width: 180px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: $color-white;
  }

  .header-dot-background {
    background-color: $profile-background;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: $profile-text;
    margin-bottom: 10px;
  }

  .myprofile {
    font-weight: bold;
  }
  .logout-btn {
    color: $primary-color;
    &:hover {
      color: $primary-color;
    }
  }
  .text-muted {
    color: $profile-text
  }
}

@media (max-width: 1200px) {
  .profile-icon {
    font-size: 22px;
  }

  .header-dot-background {
    width: 45px;
    height: 45px;
    font-size: 22px;
  }

  .card-body {
    padding: 18px;
  }
}

@media (max-width: 992px) {
  .profile-icon {
    font-size: 20px;
  }

  .header-dot-background {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }

  .card-body {
    padding: 16px;
  }
  .collapse {
   
    &.show {
     
      right: 10px !important;
     
    }
  }
}

@media (max-width: 768px) {
  .profile-icon {
    font-size: 18px;
  }

  .header-dot-background {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }

  .card-body {
    padding: 14px;
  }

  .myprofile {
    font-size: 16px;
  }

  .logout-btn {
    font-size: 14px;
  }
 
}

@media (max-width: 576px) {
  .profile-icon {
    font-size: 16px;
  }

  .header-dot-background {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }

  .card-body {
    padding: 12px;
  }

  .myprofile {
    font-size: 14px;
  }

  .logout-btn {
    font-size: 12px;
  }
}

.fixed-header {
  position: fixed !important;
  box-shadow: 0px 0px 7px 0px lightgrey;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background-color: $header-background;
  // box-shadow: 0px 0px 10px lightgrey;

}

.header-backcolor {
  color: $profile-text;
  cursor: pointer;
  &:hover {
    color: $profile-text;
  }
}

.collapse-container {
  position: relative;
  .collapse {
    &.show {
      display: block;
      position: absolute;
      right: 0;
      z-index: 1;
    }
    .card-body {
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      background-color: $color-white;
      width: 300px;
    }
  }
}

.notification-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    margin-bottom: 0;
  }
  .text-danger {
    cursor: pointer;
  }
}
.notification-date {
  color: $profile-text;
  margin: 10px 0;
}
.notification-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  cursor: pointer;
  h6 {
    margin-bottom: 0;
  }
  .notification-time {
    color:$profile-text;
  }
}

.background-dim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);

}

.notification-popup {
  width: 35%;
  position: absolute;
  top: 50px;
  right: 20px;
  z-index: 2;
  max-height: 300px;
  overflow-y: auto;
}


.notification-popup::-webkit-scrollbar {
  width: 10px; 
}

.notification-popup::-webkit-scrollbar-track {
  background: $color-disable; 
  border-radius: 10px;
}

.notification-popup::-webkit-scrollbar-thumb {
  background: $primary-color; 
  border-radius: 10px;
}





.logo {
  width: 75%;
}

.navbar-toggler {
  border: none;
}

.collapse {
  justify-content: flex-end;
}

.ms-auto {
  margin-left: auto !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}
.dot{
  height: 45px;
  width: 45px;
  background-color: $profile-background;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $profile-text;
}
.user-role-name{
  color: $profile-text;
}

.flex-lg-row {
  @media (min-width: 992px) {
    flex-direction: row !important;
  }
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

@media (max-width: 768px) {
  .navbar-text-alignment {
    flex-wrap: nowrap !important;
  }

  .user-name {
    display: none !important;
  }
}

.user-name {
  display: grid;
  align-items: center;
}

.footer {
  background-color:$address-background;
  color: $color-white;
  padding: 20px;
  text-align: center;

  h2 {
    color:  $color-white;
  }

  p {
    margin: 0;
    line-height: 1.5;
  }
}

.reset-before-button {
  background-color: $color-border;
  color: $color-white;
  border-radius: 10px;
}
.charector-required{
  font-size: 15px;
  color: $color-border;
  font-weight: 300;
}
.form-control-lg{
  font-size: 14px !important;
}

.fixing-header{
  position: fixed;
  top: 0px;
  left: 5px;
}

.notification-header {
  h4{
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $color-black;
    padding: 10px; 
    margin-bottom: 10px;
  } 
}

.display-relative-custom{
  position: relative;
}
.red-dot-notification{
  height: 7px;
  width: 7px;
  border-radius: 50px;
  background-color:$primary-color;
  position: absolute;
  top: 0;
  right: 16px;
}