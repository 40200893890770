$primary-color: #F45832;
$secondary-color: #B5B7C0;
$color-black: #000000;
$color-active: #26AC6B;
$color-bg-active: #EEF9F4;
$color-inactive: #B64343;
$color-bg-inactive: #FFEDED;
$color-inprogress: #C39900;
$color-bg-inprogress: #FEF4D3;
$color-todo: #D34053;
$color-bg-todo: #FCF0F2;
$color-review: #AC26A7;
$color-bg-review: #FFE2FE;
$color-rework: #0B15FF;
$color-bg-rework: #E0E8FF;
$color-approved: #45B003;
$color-bg-approved: #E5FFD5;
$color-error: #DD0000;
$color-hover: #E82D00;
$color-border: #9CA3AF;
$color-disable: #F2F2F2;
$color-bg-img: #F0F0F0;
$color-white: #f2f8f9;
$color-checkmail: #BDBDBD;
$color-vericalline: #DDDDDD;
$color-para: #787878;
$color-format: #9A9A9A;
$color-download: #0047FF;
$color-transprent-black: rgba(0, 0, 0, 0.5);
$color-bg-default: #FFFFFF;
$color-bg-REWORK-READY-TO-REVIEW: #E0E8FF;
$color-REWORK-READY-TO-REVIEW:#0B15FF;
$color-input-upload:#F5F5F5;
$profile-background:#D9D9D9;
$profile-text:#989898;
$search-icon:#637381;
$homepagefont:#eaecf0;
$logincheckbox:#f45832;
$maileye:#ADADAD;
$delete-backgroung: #FFF3F3;
$address-background: #333;
$remember-me:#808080;
$header-background:white;
$table-content-color:#465352;