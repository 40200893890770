@import '../Style//variable.scss';
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:$color-transprent-black;
    z-index: 999;
    display: none;
  }
  
  .modal {
    position: fixed;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    z-index: 1000;
    display: none;
  }
  
  .modal.show {
    display: block;
  }
  
  
  .modal-backdrop.show {
    z-index: 950 !important;
  }

